import { dehydrate } from '@tanstack/react-query';
import type { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import { useRouter } from 'next/router';
import requestIp from 'request-ip';
import { createFeaturedHallFilter } from '../components/organisms/Hall-products-featured/types';
import { Home as HomeTemplate, ProductSearch } from '../components/templates';
import {
    createProductSearchConfig,
    generateFilterGroups,
    getSort,
    getUrlRouter
} from '../components/templates/Product-search/hooks/useProductSearchFilters';
import {
    AuthService,
    BannerService,
    BlockService,
    CacheService,
    CategoryService,
    ElasticService,
    EstablishmentService
} from '../services';
import { IBlockAisles } from '../services/block';
import { queryClient } from '../services/query-client';
import {
    NEXT_PUBLIC_HALL_HOME,
    NEXT_PUBLIC_HOME_BANNER_1,
    NEXT_PUBLIC_HOME_BANNER_4_IMAGES,
    NEXT_PUBLIC_HOME_BANNER_COUPON,
    NEXT_PUBLIC_HOME_BANNER_MOBILE,
    NEXT_PUBLIC_HOME_BANNER_PARTNERS,
    NEXT_PUBLIC_HOME_BANNER_PRODUCTS,
    NEXT_PUBLIC_HOME_BANNER_SELLERS
} from '../utils/envs';
import {
    BANNER,
    BLOCK_AISLES,
    BLOCK_CONFIG,
    BLOCK_FOOTER_DELIVERY,
    CATEGORIES,
    CATEGORY,
    ESTABLISHMENT,
    HALL,
    PRODUCT,
    USER_LOGGED
} from '../utils/react-query-keys';
import {} from '../utils/string';

interface IHomeProps {
    detectedIp: string;
}

const Home: NextPage<IHomeProps> = (props) => {
    const router = useRouter();

    if (router?.query?.params?.length || router?.query?.q) {
        return <ProductSearch {...props} />;
    }
    return <HomeTemplate {...props} />;
};

export const getServerSidePropsCommon = ({ req, res }: GetServerSidePropsContext) => {
    return [
        queryClient.prefetchQuery([ESTABLISHMENT], () => EstablishmentService.getEstablishmentsStorage({ req, res })),
        // queryClient.prefetchQuery([USER_INFO], () => UserService.getInfo(undefined, { req, res })),
        queryClient.prefetchQuery([CATEGORIES], () =>
            CacheService.getCacheFirst(CATEGORIES, () => CategoryService.initCategory())
        ),
        queryClient.prefetchQuery([BLOCK_CONFIG], () =>
            CacheService.getCacheFirst(BLOCK_CONFIG, () => BlockService.getGeneralInfos())
        ),
        queryClient.prefetchQuery([BLOCK_FOOTER_DELIVERY], () =>
            CacheService.getCacheFirst(BLOCK_FOOTER_DELIVERY, () => BlockService.getBlockFooterDeliveryBanner())
        ),
        queryClient.prefetchQuery([USER_LOGGED], () => AuthService.checkLogged({ req, res }))
    ];
};

const getServerSidePropsSearchProduct = async (params: GetServerSidePropsContext) => {
    const { query } = params;

    const categories = (await queryClient.fetchQuery([CATEGORIES], () => CategoryService.initCategory())) || [];

    const urlKey = getUrlRouter(params);
    const isCategory = (urlKey && CategoryService.getCategoryByUrlKey(categories, urlKey)) || null;

    const { aggs, filterGroups, sortOrderField } = createProductSearchConfig({
        filterGroups: generateFilterGroups({
            searchedTerm: isCategory ? undefined : (query?.q as string),
            price: query.preco as string[],
            categoriesSelectedId:
                typeof query.subcategoria === 'string'
                    ? [parseInt(query.subcategoria)]
                    : (query.subcategoria as string[])?.map((category) => parseInt(category)),
            categoriesId: isCategory ? [Number(isCategory.id)] : [],
            marca: query.marca as string[],
            cor: query.cor as string[],
            tamanho: query.tamanho as string[],
            inflamavel: query.inflamavel as string[],
            potencia: query.potencia as string[],
            voltagem: query.voltagem as string[],
            tipo: query.tipo as string[]
        }),
        sortOrderField: getSort('relevance', isCategory)
    });

    return [
        isCategory &&
            queryClient.prefetchQuery([CATEGORY, isCategory?.id], () =>
                CacheService.getCacheFirst([CATEGORY, isCategory?.id], () =>
                    CategoryService.getCategoryById(isCategory?.id)
                )
            ),
        queryClient.prefetchInfiniteQuery([PRODUCT, filterGroups, sortOrderField, aggs], () =>
            ElasticService.getProductsInfiniteQuery({ pageParam: 1, filterGroups, sortOrderField, aggs })
        )
    ];
};

export const getServerSideProps: GetServerSideProps = async (params) => {
    const detectedIp =
        process.env.NODE_ENV === 'production'
            ? requestIp.getClientIp(params.req)
            : '2804:14c:5f82:4227:d930:9171:a651:604d';

    if (params?.query?.q || params?.query?.params?.length) {
        await Promise.all([...getServerSidePropsCommon(params), getServerSidePropsSearchProduct(params)]);

        const dehydratedState = dehydrate(queryClient);
        const sanitizedDehydratedState = JSON.parse(
            JSON.stringify(dehydratedState, (key, value) => (value === undefined ? null : value))
        );
        return {
            props: { dehydratedState: sanitizedDehydratedState, detectedIp }
        };
    }

    const hallHome: IBlockAisles[] = await queryClient.fetchQuery([BLOCK_AISLES, NEXT_PUBLIC_HALL_HOME], () =>
        CacheService.getCacheFirst([BLOCK_AISLES, NEXT_PUBLIC_HALL_HOME], () =>
            BlockService.getBlockAisle(NEXT_PUBLIC_HALL_HOME)
        )
    );

    await Promise.all([
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_1], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_1], () =>
                BannerService.getById(NEXT_PUBLIC_HOME_BANNER_1)
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_SELLERS], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_SELLERS], () =>
                BannerService.getById(NEXT_PUBLIC_HOME_BANNER_SELLERS)
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_PARTNERS], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_PARTNERS], () =>
                BannerService.getById(NEXT_PUBLIC_HOME_BANNER_PARTNERS)
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_MOBILE], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_MOBILE], () =>
                BannerService.getById(NEXT_PUBLIC_HOME_BANNER_MOBILE)
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_PRODUCTS], () =>
            BannerService.getBannerWithProducts(NEXT_PUBLIC_HOME_BANNER_PRODUCTS, params)
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_COUPON], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_COUPON], () =>
                BannerService.getBannerResponsiveById(NEXT_PUBLIC_HOME_BANNER_COUPON)
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_4_IMAGES], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_4_IMAGES], () =>
                BannerService.getBannerResponsiveById(NEXT_PUBLIC_HOME_BANNER_4_IMAGES)
            )
        ),

        ...hallHome.map((item, id) => {
            return queryClient.prefetchQuery([HALL, item.title, id.toString()], () => {
                return ElasticService.getProducts({
                    filter: createFeaturedHallFilter(item.categories),
                    limit: 30,
                    page: 1,
                    cookiesParams: { req: params.req, res: params.res },
                    sort: item.categories.map((item) => ({
                        [`position_category_${item}`]: {
                            order: 'asc'
                        }
                    }))
                });
            });
        }),
        ...getServerSidePropsCommon(params)
    ]);
    const dehydratedState = dehydrate(queryClient);
    const sanitizedDehydratedState = JSON.parse(
        JSON.stringify(dehydratedState, (key, value) => (value === undefined ? null : value))
    );
    return {
        props: { dehydratedState: sanitizedDehydratedState, detectedIp }
    };
};

export default Home;
